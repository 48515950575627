import React, { useState } from 'react';
import { Table, Button, Form, Container, Alert, Pagination } from 'react-bootstrap';
import axios from 'axios';

const LegReport = () => {
    const customerId = sessionStorage.getItem('customerId');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [legReport, setLegReport] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Number of items per page

    const fetchLegReport = () => {
        // Validate Customer ID and End Date if Start Date is provided
        if (!customerId) {
            setError("Please enter Customer ID.");
            setLegReport([]);
            return;
        }
        if (startDate && !endDate) {
            setError("Please enter an End Date if Start Date is selected.");
            setLegReport([]);
            return;
        }

        // Clear any previous errors
        setError(null);

        // Build the URL with optional start and end dates
        let url = `/leg-report/${customerId}`;
        if (startDate) {
            url += `?start_date=${startDate}&end_date=${endDate}`;
        }

        // API request to fetch leg report data
        axios.get(url)
            .then((response) => {
                const data = response.data.leg_report;
                setLegReport(data);
                setCurrentPage(1); // Reset to first page on new search

                // Show "No data available" message if data is empty
                if (data.length === 0) {
                    setError("No data available for the specified criteria.");
                }
            })
            .catch(() => {
                setError("No leg report found for the specified criteria.");
                setLegReport([]);
            });
    };

    // Calculate the items to display on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = legReport.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Generate pagination items based on the number of pages
    const totalPages = Math.ceil(legReport.length / itemsPerPage);
    const paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
            <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
                {i}
            </Pagination.Item>
        );
    }

    return (
        <Container className="mt-5">
            <h2>Leg Report</h2>
            <Form.Group className="mb-4">
                <Form.Label>Enter Customer ID, Start Date (optional), and End Date:</Form.Label>
                <div className="d-flex">
                    <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => {
                            setStartDate(e.target.value);
                            if (!e.target.value) setEndDate('');
                        }}
                        className="ms-2"
                    />
                    <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="ms-2"
                        disabled={!startDate} // Enable only if Start Date is selected
                    />
                    <Button variant="dark" className="ms-2" onClick={fetchLegReport}>Search</Button>
                </div>
                {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
            </Form.Group>

            {currentItems.length > 0 ? (
                <>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Leg ID</th>
                                <th>Customer ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Level</th>
                                <th>Order Month</th>
                                <th>Total BV From Leg</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.LegID}</td>
                                    <td>{data.CustomerID}</td>
                                    <td>{data.FirstName}</td>
                                    <td>{data.LastName}</td>
                                    <td>{data.Level}</td>
                                    <td>{data.OrderMonth}</td>
                                    <td>{Math.round(data.TotalBVFromLeg)}</td> {/* No decimal points */}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination className="justify-content-center mt-3">
                        {paginationItems}
                    </Pagination>
                </>
            ) : (
                !error && <Alert variant="dark" className="text-center">Please enter Customer ID, and optionally Start and End Dates, to view the leg report.</Alert>
            )}
        </Container>
    );
};

export default LegReport;


