


import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Container, Alert } from 'react-bootstrap';
import axios from 'axios';
import ApexTree from "apextree";
import './TreeViewer.css';

const TreeViewer = () => {
    const customerId = sessionStorage.getItem('customerId');
    const [downlineData, setDownlineData] = useState([]);
    const [error, setError] = useState(null);
    const [noData, setNoData] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const treeContainer = useRef(null);
    const [level0Details, setLevel0Details] = useState(null);
    const [onlyPartners, setOnlyPartners] = useState(true);

    const fetchDownlineData = () => {
        if (!customerId) {
            setError("Please enter a valid Customer ID");
            setDownlineData([]);
            setNoData(false);
            setLevel0Details(null);
            return;
        }
        axios.get(`/downline-data/${customerId}?only_partners=${onlyPartners}`)
            .then((response) => {
                const data = response.data.downline_data || [];
                setDownlineData(data);
                setError(null);
                setNoData(data.length === 0);

                const level0Customer = data.find(item => item.Level === 0);
                setLevel0Details(level0Customer || null);
            })
            .catch(() => {
                setError("No downline data found for this Customer ID.");
                setDownlineData([]);
                setNoData(true);
                setLevel0Details(null);
            });
    };

    const transformDataToTree = (data) => {
        try {
            const dataMap = {};
            data.forEach((item) => {
                dataMap[item.CustomerID] = {
                    id: item.CustomerID.toString(),
                    name: `${item.FirstName} ${item.LastName} (CustomerID: ${item.CustomerID})<br/>(Sales: ${item.TotalOrders})<br/>(Total Sales: ${item.TotalSubTotalAmount})`,
                    children: [],
                };
            });

            const tree = [];
            data.forEach((item) => {
                if (item.Level === 0) {
                    tree.push(dataMap[item.CustomerID]);
                } else {
                    if (dataMap[item.EnrollerID]) {
                        dataMap[item.EnrollerID].children.push(dataMap[item.CustomerID]);
                    }
                }
            });

            return tree;
        } catch (err) {
            console.error("Error transforming data to tree:", err);
            setError("An error occurred while processing the tree structure.");
            return [];
        }
    };

    // Fetch downline data on component mount
    useEffect(() => {
        fetchDownlineData();
    }, []);

    useEffect(() => {
        if (downlineData.length > 0 && treeContainer.current) {
            try {
                treeContainer.current.innerHTML = "";

                const treeData = transformDataToTree(downlineData);

                if (treeData.length === 0) {
                    setError("No Data available.");
                    return;
                }

                const options = {
                    contentKey: 'name',
                    width: 1000,
                    height: 800,
                    nodeWidth: 150,
                    nodeHeight: 100,
                    childrenSpacing: 120,
                    siblingSpacing: 40,
                    direction: 'top',
                    enableToolbar: true,
                    canvasStyle: 'border: 1px solid black; background: #f6f6f6;',
                };

                const tree = new ApexTree(treeContainer.current, options);
                tree.render(treeData[0]);
            } catch (err) {
                console.error("Error rendering the tree:", err);
                setError("An error occurred while rendering the tree.");
            }
        }
    }, [downlineData]);

    const handleZoomIn = () => setZoomLevel((prev) => prev + 0.1);
    const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.1, 0.1));

    /**
     * Run the query an render the tree
     * @param {*} e 
     */
    const queryTreeData = (e) => {
        setOnlyPartners(!e);
        fetchDownlineData();
    }

    return (
        <Container className="mt-5">
            <h2>Tree Viewer</h2>
            <Form.Group className="mb-4">
                <Form.Check type="checkbox" label="Only partners" onChange={(e) => queryTreeData(e.target.checked)} value={onlyPartners} />
                {error && <Alert variant="dark" className="mt-2">{error}</Alert>}
            </Form.Group>

            {noData && !error && (
                <Alert variant="dark" className="text-center">
                    No data available for the provided Customer ID.
                </Alert>
            )}
{!noData && level0Details && (
    <div className="customer-details">
        <div className="customer-box">
            <p><strong>Name:</strong> {level0Details.FirstName} {level0Details.LastName}</p>
        </div>
        <div className="customer-box">
            <p><strong>Customer ID:</strong> {level0Details.CustomerID}</p>
        </div>
    </div>
)}


            {!noData && downlineData.length > 0 && (
                <div className="zoom-controls mb-2">
                    <Button variant="secondary" onClick={handleZoomOut} className="me-2">Zoom Out</Button>
                    <Button variant="secondary" onClick={handleZoomIn}>Zoom In</Button>
                </div>
            )}

            {!noData && downlineData.length > 0 && (
                <div className="tree-container" style={{ overflow: "auto" }}>
                    <div
                        ref={treeContainer}
                        id="svg-tree"
                        style={{
                            transform: `scale(${zoomLevel})`,
                            transformOrigin: "top left",
                            width: "1000px",
                            height: "800px",
                            margin: "0 auto",
                        }}
                    />
                </div>
            )}

            {!error && downlineData.length === 0 && !noData && (
                <Alert variant="dark" className="text-center">
                    Please enter a Customer ID to view downline data.
                </Alert>
            )}
        </Container>
    );
};

export default TreeViewer;

