import React, { useState } from 'react';
import { Table, Button, Form, Container, Alert } from 'react-bootstrap';
import axios from 'axios';

const OverallPoints = () => {
    const [customerId, setCustomerId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [overallPoints, setOverallPoints] = useState([]);
    const [error, setError] = useState(null);

    const fetchOverallPoints = () => {
        if (!customerId) {
            setError("Please enter a valid Customer ID");
            setOverallPoints([]);
            return;
        }

        const params = { customerId };
        if (startDate) params.startDate = startDate;
        if (endDate) params.endDate = endDate;

        axios.get(`/overall-points/${customerId}`, { params })
            .then((response) => {
                const data = response.data.overall_points;
                setOverallPoints(data);
                setError(null);

                if (data.length === 0) {
                    setError("No data available for this Customer ID.");
                }
            })
            .catch(() => {
                setError("No overall points data found for this Customer ID.");
                setOverallPoints([]);
            });
    };

    return (
        <Container className="mt-5">
            <h2>Overall Points</h2>
            <Form.Group className="mb-4">
                <div className="d-flex align-items-center">
                    <Form.Label className="me-2">Customer ID:</Form.Label>
                    <Form.Control
                        type="text"
                        value={customerId}
                        onChange={(e) => setCustomerId(e.target.value)}
                        placeholder="Enter Customer ID"
                        className="me-3"
                    />
                    <Form.Label className="me-2">Start Date:</Form.Label>
                    <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="me-3"
                    />
                    <Form.Label className="me-2">End Date:</Form.Label>
                    <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="me-3"
                    />
                    <Button onClick={fetchOverallPoints}>Search</Button>
                </div>
                {error && <p className="text-danger mt-2">{error}</p>}
            </Form.Group>

            {overallPoints.length > 0 ? (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            {/* <th>Customer ID</th> */}
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Account Type</th>
                            <th>Total Points</th>
                            <th>Points to Next Level</th>
                            <th>Week Number</th>
                            <th>Transaction Date</th>
                            <th>Points Earned</th>
                            <th>Points Accumulated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {overallPoints.map((data, index) => (
                            <tr key={index}>
                                {/* <td>{data.CustomerID}</td> */}
                                <td>{data.FirstName}</td>
                                <td>{data.LastName}</td>
                                <td>{data.AccountType}</td>
                                <td>{data.TotalPoints}</td>
                                <td>{data.PointsToNextLevel}</td>
                                <td>{data.WeekNumber}</td>
                                <td>{new Date(data.TransactionDate).toLocaleDateString()}</td>
                                <td>{data.PointsEarned}</td>
                                <td>{data.PointsAccumulated}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                !error && <Alert variant="info" className="text-center">Please enter a Customer ID to view overall points data.</Alert>
            )}
        </Container>
    );
};

export default OverallPoints;
