import React, { useState } from 'react';
import { Table, Button, Form, Container, Alert, Pagination } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const OrderDetails = () => {
    const [customerId, setCustomerId] = useState('');
    const [orderDetails, setOrderDetails] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const itemsPerPage = 10;

    const fetchOrderDetails = () => {
        if (!customerId) {
            setError("Please enter a valid Customer ID");
            setOrderDetails([]);
            return;
        }

        axios.get(`/order-details/${customerId}`)
            .then((response) => {
                const data = response.data.order_details;
                setOrderDetails(data);
                setError(null);
                setCurrentPage(1); // Reset to the first page on new data fetch

                // Show "No data available" if the fetched data is empty
                if (data.length === 0) {
                    setError("No data available for this Customer ID.");
                }
            })
            .catch((error) => {
                console.error("Error fetching order details", error);
                setError("No order details found for this Customer ID.");
                setOrderDetails([]);
            });
    };

    // Sorting function
    const sortedOrderDetails = [...orderDetails].sort((a, b) => {
        if (sortConfig.key) {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];
            if (sortConfig.key === 'OrderDate') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            }
            return sortConfig.direction === 'ascending'
                ? aValue < bValue ? -1 : 1
                : aValue > bValue ? -1 : 1;
        }
        return 0;
    });

    // Filter orderDetails based on the search term
    const filteredOrderDetails = sortedOrderDetails.filter((order) =>
        Object.values(order).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredOrderDetails.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredOrderDetails.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const renderSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    return (
        <Container className="mt-5">
            <h2>Order Details</h2>
            <Form.Group className="mb-4" controlId="customerIdInput">
                <Form.Label>Enter Customer ID:</Form.Label>
                <div className="d-flex">
                    <Form.Control
                        type="text"
                        value={customerId}
                        onChange={(e) => setCustomerId(e.target.value)}
                        placeholder="Enter Customer ID"
                    />
                    <Button className="ms-2" onClick={fetchOrderDetails}>
                        Fetch Orders
                    </Button>
                </div>
                {error && <p className="text-danger mt-2">{error}</p>}
            </Form.Group>

            {/* Search Input */}
            {orderDetails.length > 0 && (
                <Form.Group className="mb-4" controlId="searchInput">
                    <Form.Label>Search Table:</Form.Label>
                    <Form.Control
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search by any field"
                    />
                </Form.Group>
            )}

            {/* Default Prompt for Customer ID */}
            {(!customerId || orderDetails.length === 0) && !error && (
                <Alert variant="info" className="text-center">
                    Please enter a Customer ID to search order details.
                </Alert>
            )}

            {filteredOrderDetails.length > 0 && (
                <>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('OrderID')}>Order ID {renderSortIcon('OrderID')}</th>
                                <th onClick={() => handleSort('OrderDate')}>Order Date {renderSortIcon('OrderDate')}</th>
                                <th onClick={() => handleSort('OrderTotalAmount')}>Total Amount {renderSortIcon('OrderTotalAmount')}</th>
                                <th onClick={() => handleSort('ItemCode')}>Item Code {renderSortIcon('ItemCode')}</th>
                                <th onClick={() => handleSort('ItemDescription')}>Item Description {renderSortIcon('ItemDescription')}</th>
                                <th onClick={() => handleSort('Quantity')}>Quantity {renderSortIcon('Quantity')}</th>
                                <th onClick={() => handleSort('PriceEach')}>Price Each {renderSortIcon('PriceEach')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((order, index) => (
                                <tr key={index}>
                                    <td>{order.OrderID}</td>
                                    <td>{new Date(order.OrderDate).toLocaleDateString()}</td>
                                    <td>{order.OrderTotalAmount}</td>
                                    <td>{order.ItemCode}</td>
                                    <td>{order.ItemDescription}</td>
                                    <td>{order.Quantity}</td>
                                    <td>{order.PriceEach}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                        <Pagination className="justify-content-center mt-4">
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            {Array.from({ length: totalPages }, (_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                        </Pagination>
                    )}
                </>
            )}
        </Container>
    );
};

export default OrderDetails;
