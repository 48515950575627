import React, { useState } from 'react';
import { Table, Button, Form, Container, Alert } from 'react-bootstrap';
import axios from 'axios';

const CommissionPayout = () => {
    const customerId = sessionStorage.getItem('customerId');
    const [filterType, setFilterType] = useState('all_time'); // State for filter type
    const [commissionData, setCommissionData] = useState([]);
    const [error, setError] = useState(null);

    const fetchCommissionData = () => {
        if (!customerId) {
            setError("Please enter a valid Customer ID");
            setCommissionData([]);
            return;
        }

        // Fetch data with filter type
        axios
            .get(`/commission-data/${customerId}`, {
                params: { date_filter: filterType }
            })
            .then((response) => {
                const data = response.data.commission_data;
                setCommissionData(data);
                setError(null);

                // Show "No data available" if the fetched data is empty
                if (data.length === 0) {
                    setError("No data available for this Customer ID.");
                }
            })
            .catch(() => {
                setError("No commission data found for this Customer ID.");
                setCommissionData([]);
            });
    };

    return (
        <Container className="mt-5">
            <h2>Commission Payout</h2>
            <Form.Group className="mb-4">
                <Form.Label>Enter Customer ID:</Form.Label>
                <div className="d-flex">
                    <Form.Select
                        className="ms-2"
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                    >
                        <option value="all_time">All Time</option>
                        <option value="current_month">Current Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="year_to_date">Year to Date</option>
                    </Form.Select>
                    <Button variant="dark" className="ms-2" onClick={fetchCommissionData}>
                        Search
                    </Button>
                </div>
                {error && <p className="text-danger mt-2">{error}</p>}
            </Form.Group>

            {commissionData.length > 0 ? (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Commission Run ID</th>
                            <th>Description</th>
                            <th>Detail ID</th>
                            <th>Customer First Name</th>
                            <th>Customer Last Name</th>
                            <th>Bonus ID</th>
                            <th>Order ID</th>
                            <th>Order Amount ($)</th>
                            <th>Commission Amount ($)</th>
                            <th>Percentage</th>
                            <th>Level</th>
                        </tr>
                    </thead>
                    <tbody>
                        {commissionData.map((data, index) => (
                            <tr key={index}>
                                <td>{data.CommissionRunID}</td>
                                <td>{data.CommissionRunDescription}</td>
                                <td>{data.CommissionDetailID}</td>
                                <td>{data.CustomerFirstName}</td>
                                <td>{data.CustomerLastName}</td>
                                <td>{data.BonusID}</td>
                                <td>{data.OrderID}</td>
                                <td>{parseFloat(data.SourceAmount).toFixed(2)}</td> {/* Updated to 2 decimal places */}
                                <td>{parseFloat(data.CommissionAmount).toFixed(2)}</td> {/* Updated to 2 decimal places */}
                                <td>{parseFloat(data.Percentage).toFixed(1)}</td>
                                <td>{data.Level}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                !error && (
                    <Alert variant="dark" className="text-center">
                        Please enter a Customer ID to view commission data.
                    </Alert>
                )
            )}
        </Container>
    );
};

export default CommissionPayout;

