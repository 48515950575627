import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Container, Alert, Pagination } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import{ useParams } from 'react-router-dom';

const Orders = () => {
    let customerId = useParams().customerId;
    const [ordersSummary, setOrdersSummary] = useState(null);
    const [orderDetails, setOrderDetails] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const itemsPerPage = 10;

    const fetchOrderData = () => {
        if (!customerId) {
            if (!customerId) {
                setError("No Customer ID provided.");
                setOrdersSummary(null);
                setOrderDetails([]);
                return;
            }
        }
        sessionStorage.setItem('customerId', customerId);

        // Fetch Orders Summary
        axios.get(`/orders-summary/${customerId}`)
            .then((response) => {
                const data = response.data.orders_summary;
                setOrdersSummary(data);
                setError(null);
                if (!data || Object.keys(data).length === 0) {
                    setError("No orders summary available for this Customer ID.");
                }
            })
            .catch(() => {
                setError("No orders summary found for this Customer ID.");
                setOrdersSummary(null);
            });

        // Fetch Order Details
        axios.get(`/order-summary-details/${customerId}`)
            .then((response) => {
                const data = response.data.order_details;
                setOrderDetails(data);
                setError(null);
                setCurrentPage(1);
                if (data.length === 0) {
                    setError("No order details available for this Customer ID.");
                }
            })
            .catch(() => {
                setError("No order details found for this Customer ID.");
                setOrderDetails([]);
            });
    };

    // Calculate total number of purchase products and total amount for each order
    const calculateProductCountAndTotal = (orders) => {
        return orders.map((order) => {
            const items = order.Items.split(','); // Assuming Items is a comma-separated string of products
            const productCount = items.length; // Count the number of products
            const totalAmount = parseFloat(order.OrderTotalAmount || 0); // Ensure it's a number

            return {
                ...order,
                TotalNumberOfPurchaseProducts: productCount,
                TotalAmount: totalAmount,
            };
        });
    };

    // Transform and calculate additional data for orderDetails
    const transformedOrderDetails = calculateProductCountAndTotal(orderDetails);

    // Sorting function
    const sortedOrderDetails = [...transformedOrderDetails].sort((a, b) => {
        if (sortConfig.key) {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];
            if (sortConfig.key === 'OrderDate') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            }
            return sortConfig.direction === 'ascending'
                ? aValue < bValue ? -1 : 1
                : aValue > bValue ? -1 : 1;
        }
        return 0;
    });

    // Filter orderDetails based on the search term
    const filteredOrderDetails = sortedOrderDetails.filter((order) =>
        Object.values(order).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredOrderDetails.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredOrderDetails.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const renderSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    useEffect(() => {
        fetchOrderData();
    }, []);

    return (
        <Container className="mt-5">
            <h2>Orders Summary</h2>

            {/* Orders Summary Table */}
            {ordersSummary && (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Total Number of Orders</th>
                            <th>Total Amount ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ordersSummary.TotalNumberOfOrders}</td>
                            <td>{ordersSummary.TotalAmountOfAllOrders}</td>
                        </tr>
                    </tbody>
                </Table>
            )}

            {/* Search Input for Order Details */}
            {orderDetails.length > 0 && (
                <Form.Group className="mb-4" controlId="searchInput">
                    <Form.Label>Search Table:</Form.Label>
                    <Form.Control
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search by any field"
                    />
                </Form.Group>
            )}

            {/* Order Details Table */}
            {filteredOrderDetails.length > 0 && (
                <>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('OrderID')}>Order ID {renderSortIcon('OrderID')}</th>
                                <th onClick={() => handleSort('OrderDate')}>Order Date {renderSortIcon('OrderDate')}</th>
                                <th>Total Amount ($) {renderSortIcon('TotalAmount')}</th>
                                <th onClick={() => handleSort('Items')}>Items {renderSortIcon('Items')}</th>
                                <th>Total Number of Purchase Products {renderSortIcon('TotalNumberOfPurchaseProducts')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((order, index) => (
                                <tr key={index}>
                                    <td>{order.OrderID}</td>
                                    <td>{new Date(order.OrderDate).toLocaleDateString()}</td>
                                    <td>{order.TotalAmount.toFixed(2)}</td>
                                    <td>{order.Items}</td>
                                    <td>{order.TotalNumberOfPurchaseProducts}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                        <Pagination className="justify-content-center mt-4">
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            {Array.from({ length: totalPages }, (_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                        </Pagination>
                    )}
                </>
            )}
        </Container>
    );
};

export default Orders;

