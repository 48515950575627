import React, { useState } from 'react';
import { Table, Button, Form, Container, Alert, Pagination } from 'react-bootstrap';
import axios from 'axios';

const PersonallyEnrolledTeam = () => {
    const [enrollerId, setEnrollerId] = useState('');
    const [filterType, setFilterType] = useState('All Time'); // State for filter type
    const [teamData, setTeamData] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const itemsPerPage = 10;

    const fetchTeamData = () => {
        if (!enrollerId) {
            setError("Please enter a valid Enroller ID");
            setTeamData([]);
            return;
        }

        // Fetch data with filter type
        axios.get(`/personally-enrolled-team/${enrollerId}`, {
            params: { filter_type: filterType }
        })
            .then((response) => {
                setTeamData(response.data.personally_enrolled_team);
                setError(null);
                setCurrentPage(1);
            })
            .catch(() => {
                setError("No team data found for this Enroller ID.");
                setTeamData([]);
            });
    };

    const sortedTeamData = [...teamData].sort((a, b) => {
        if (sortConfig.key) {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];
            if (sortConfig.key === 'StartDate') {
                aValue = new Date(aValue);
                bValue = new Date(bValue);
            }
            return sortConfig.direction === 'ascending'
                ? aValue < bValue ? -1 : 1
                : aValue > bValue ? -1 : 1;
        }
        return 0;
    });

    const filteredTeamData = sortedTeamData.filter((member) =>
        Object.values(member).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredTeamData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredTeamData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const renderSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    return (
        <Container className="mt-5">
            <h2>Personally Enrolled Team</h2>
            <Form.Group className="mb-4">
                <Form.Label>Enter Enroller ID:</Form.Label>
                <div className="d-flex">
                    <Form.Control
                        type="text"
                        value={enrollerId}
                        onChange={(e) => setEnrollerId(e.target.value)}
                        placeholder="Enter Enroller ID"
                    />
                    <Form.Select
                        className="ms-2"
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                    >
                        <option value="All Time">All Time</option>
                        <option value="The Current Month">The Current Month</option>
                        <option value="Last Month">Last Month</option>
                        <option value="Last Quarter">Last Quarter</option>
                        <option value="Year to Date">Year to Date</option>
                    </Form.Select>
                    <Button variant="dark" className="ms-2" onClick={fetchTeamData}>Search</Button>
                </div>
                {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
            </Form.Group>

            {teamData.length > 0 && (
                <Form.Group className="mb-4">
                    <Form.Label>Search Table:</Form.Label>
                    <Form.Control
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search by any field"
                    />
                </Form.Group>
            )}

            {filteredTeamData.length > 0 ? (
                <>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('ID')}>ID {renderSortIcon('ID')}</th>
                                <th onClick={() => handleSort('FirstName')}>First Name {renderSortIcon('FirstName')}</th>
                                <th onClick={() => handleSort('LastName')}>Last Name {renderSortIcon('LastName')}</th>
                                <th onClick={() => handleSort('Email')}>Email {renderSortIcon('Email')}</th>
                                <th onClick={() => handleSort('Phone')}>Phone {renderSortIcon('Phone')}</th>
                                <th onClick={() => handleSort('StartDate')}>Start Date {renderSortIcon('StartDate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((member, index) => (
                                <tr key={index}>
                                    <td>{member.ID}</td>
                                    <td>{member.FirstName}</td>
                                    <td>{member.LastName}</td>
                                    <td>{member.Email}</td>
                                    <td>{member.Phone}</td>
                                    <td>{new Date(member.StartDate).toLocaleDateString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {totalPages > 1 && (
                        <Pagination className="justify-content-center mt-4">
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            {Array.from({ length: totalPages }, (_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                        </Pagination>
                    )}
                </>
            ) : (
                !error && <Alert variant="dark" className="text-center">Please enter an Enroller ID to view team data.</Alert>
            )}
        </Container>
    );
};

export default PersonallyEnrolledTeam;
