import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt,faFolderTree, faChartBar, faUsers, faBars,faCartShopping ,faMoneyCheckDollar,faChartSimple}
 from '@fortawesome/free-solid-svg-icons';
import { Route, Routes, NavLink, useParams } from 'react-router-dom';
import TreeViewer from './TreeViewer';
import CommissionPayout from './CommissionPayout';
import Orders from './Orders';
import PersonallyEnrolledTeam from './PersonallyEnrolledTeam';
import LegReport from './LegReport';
import CommissionChart from './CommissionChart';
import OverallPoints from './OverallPoints';
import OrderDetails from './OrderDetails';

import './assets/css/business-dashboard.css';
import './assets/css/login-process.css';
import './assets/css/slick.css';
import './assets/css/style.css';
import './Dashboard.css';

const Sidebar = ({ isCollapsed, toggleSidebar }) => {
    return (
        <div className={`dashboard-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <div className="close_icon">
                <button onClick={toggleSidebar} className="menu_icon">
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>
            <div className="dashboard-nav">
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink to="tree-viewer" className="nav-link" activeClassName="active">
                        <FontAwesomeIcon icon={faFolderTree} />
                            <span>Tree Viewer</span>
                        </NavLink>
                    </li>
                    {/*
                    <li className="nav-item">
                        <NavLink to="orders" className="nav-link" activeClassName="active">
                        <FontAwesomeIcon icon={faCartShopping} />
                            <span>Orders</span>
                        </NavLink>
                    </li>
                    */}
                    {/* <li className="nav-item">
                        <NavLink to="order-details" className="nav-link" activeClassName="active">
                            <FontAwesomeIcon icon={faTachometerAlt} />
                            <span>Order Details</span>
                        </NavLink>
                    </li>
                    */}
                    {/*
                    <li className="nav-item">
                        <NavLink to="commission-payout" className="nav-link" activeClassName="active">
                        <FontAwesomeIcon icon={faMoneyCheckDollar} />
                            <span>Commission Payout</span>
                        </NavLink>
                    </li>
                    */}
                   
                   {/*
                    <li className="nav-item">
                        <NavLink to="commission-chart" className="nav-link" activeClassName="active">
                        <FontAwesomeIcon icon={faChartSimple} />
                            <span>Commission Chart</span>
                        </NavLink>
                    </li>
                    */}

                    {/*}
                    <li className="nav-item">
                        <NavLink to="personally-enrolled-team" className="nav-link" activeClassName="active">
                        <FontAwesomeIcon icon={faUsers} />
                            <span>Personally Enrolled Team</span>
                        </NavLink>
                    </li>
                    */}

                    
                    {/* 
                    <li className="nav-item">
                        <NavLink to="overall-points" className="nav-link" activeClassName="active">
                            <FontAwesomeIcon icon={faTachometerAlt} />
                            <span>Overall Points</span>
                        </NavLink>
                    </li>
                    */}

                    {/*
                    <li className="nav-item">
                        <NavLink to="leg-report" className="nav-link" activeClassName="active">
                            <FontAwesomeIcon icon={faChartBar} />
                            <span>Leg Report</span>
                        </NavLink>
                    </li>
                   */}
                </ul>
            </div>
            {/* <div className="dashboard-logout">
                <NavLink to="/" className="logout-link">
                    Logout
                </NavLink>
            </div> */}
        </div>
    );
};

const Dashboard = () => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const { customerId } = useParams();

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    useEffect(() => {
        sessionStorage.setItem('customerId', customerId);
    }, []);

    return (
        <div className="business-dashboard">
            <div className="mainpanel-nav head">
                <div className="top_left">
                    <h3>Ayucell Backoffice</h3>
                    <p>Today, {new Date().toLocaleDateString()}</p>
                </div>
                <div className="top_right header_login">
                    <button onClick={toggleSidebar} className="menu_icon">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
            </div>

            <div className="dashboard-container">
                <div className={`sidebar-container ${isSidebarCollapsed ? 'collapsed' : ''}`}>
                    <Sidebar isCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} />
                </div>

                <div className="dashboard-panel-content">
                    <Routes>
                        <Route path="tree-viewer" element={<TreeViewer />} />
                        <Route path="commission-payout" element={<CommissionPayout />} />
                        <Route path="orders" element={<Orders />} />
                        <Route path="personally-enrolled-team" element={<PersonallyEnrolledTeam />} />
                        <Route path="leg-report" element={<LegReport />} />
                        <Route path="commission-chart" element={<CommissionChart />} />
                        <Route path="overall-points" element={<OverallPoints />} />
                        <Route path="order-details" element={<OrderDetails />} />
                        <Route path="*" element={<h2>Select an option from the menu</h2>} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
