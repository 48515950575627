import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import axios from 'axios';


function App() {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    
    return (
        <Router>
            <Routes>
                <Route path="/:customerId*" element={<Dashboard />} />
            </Routes>
        </Router>
    );
}

export default App;
