

// // export default CommissionChart;
// import React, { useState } from 'react';
// import axios from 'axios';
// import { Container, Form, Button, Table, Alert } from 'react-bootstrap';

// const CommissionChart = () => {
//     const [customerId, setCustomerId] = useState('');
//     const [commissionChart, setCommissionChart] = useState([]);
//     const [error, setError] = useState(null);

//     const fetchCommissionChart = () => {
//         if (!customerId) {
//             setError("Please enter a valid Customer ID");
//             setCommissionChart([]);
//             return;
//         }

//         axios.get(`/commission-chart/${customerId}`)
//             .then((response) => {
//                 const data = response.data.commission_chart;
//                 setCommissionChart(data);
//                 setError(null);

//                 if (data.length === 0) {
//                     setError("No data available for this Customer ID.");
//                 }
//             })
//             .catch(() => {
//                 setError("No commission chart data found for this Customer ID.");
//                 setCommissionChart([]);
//             });
//     };

//     return (
//         <Container className="mt-5">
//             <h2 >Commission Chart</h2>
//             <Form.Group className="mb-4">
//                 <Form.Label>Enter Customer ID:</Form.Label>
//                 <div className="d-flex">
//                     <Form.Control
//                         type="text"
//                         value={customerId}
//                         onChange={(e) => setCustomerId(e.target.value)}
//                         placeholder="Enter Customer ID"
//                     />
//                     <Button variant="dark" className="ms-2" onClick={fetchCommissionChart}>Search</Button>
//                 </div>
//                 {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
//             </Form.Group>

//             {commissionChart.length > 0 ? (
//                 <Table striped bordered hover responsive>
//                     <thead>
//                         <tr>
//                             <th>Commission Run ID</th>
//                             <th>Type</th>
//                             <th>Run Date</th>
//                             <th>Accepted Date</th>
//                             <th>Commission Total ($)</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {commissionChart.map((data, index) => (
//                             <tr key={index}>
//                                 <td>{data.CommissionRunID}</td>
//                                 <td>{data.Type}</td>
//                                 <td>{new Date(data.RunDate).toLocaleDateString()}</td>
//                                 <td>{new Date(data.AcceptedDate).toLocaleDateString()}</td>
//                                 <td>{parseFloat(data.CommissionTotal).toFixed(1)}</td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </Table>
//             ) : (
//                 !error && (
//                     <Alert variant="dark" className="text-center">
//                         Please enter a Customer ID to view commission chart data.
//                     </Alert>
//                 )
//             )}
//         </Container>
//     );
// };

// export default CommissionChart;

import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Table, Alert } from 'react-bootstrap';

const CommissionChart = () => {
    const customerId = sessionStorage.getItem('customerId');
    const [filterType, setFilterType] = useState('All Time'); // State for filter type
    const [commissionChart, setCommissionChart] = useState([]);
    const [error, setError] = useState(null);

    const fetchCommissionChart = () => {
        if (!customerId) {
            setError("Please enter a valid Customer ID");
            setCommissionChart([]);
            return;
        }

        // API request with filter type
        axios.get(`/commission-chart/${customerId}`, {
            params: { filter_type: filterType }
        })
            .then((response) => {
                const data = response.data.commission_chart;
                setCommissionChart(data);
                setError(null);

                if (data.length === 0) {
                    setError("No data available for this Customer ID.");
                }
            })
            .catch(() => {
                setError("No commission chart data found for this Customer ID.");
                setCommissionChart([]);
            });
    };

    return (
        <Container className="mt-5">
            <h2>Commission Chart</h2>
            <Form.Group className="mb-4">
                <div className="d-flex">
                    <Form.Select
                        className="ms-2"
                        value={filterType}
                        onChange={(e) => setFilterType(e.target.value)}
                    >
                        <option value="All Time">All Time</option>
                        <option value="The Current Month">The Current Month</option>
                        <option value="Last Month">Last Month</option>
                        <option value="Last Quarter">Last Quarter</option>
                        <option value="Year to Date">Year to Date</option>
                    </Form.Select>
                    <Button variant="dark" className="ms-2" onClick={fetchCommissionChart}>Search</Button>
                </div>
                {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
            </Form.Group>

            {commissionChart.length > 0 ? (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Commission Run ID</th>
                            <th>Type</th>
                            <th>Run Date</th>
                            <th>Accepted Date</th>
                            <th>Commission Total ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {commissionChart.map((data, index) => (
                            <tr key={index}>
                                <td>{data.CommissionRunID}</td>
                                <td>{data.Type}</td>
                                <td>{new Date(data.RunDate).toLocaleDateString()}</td>
                                <td>{new Date(data.AcceptedDate).toLocaleDateString()}</td>
                                <td>{parseFloat(data.CommissionTotal).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                !error && (
                    <Alert variant="dark" className="text-center">
                        Please enter a Customer ID to view commission chart data.
                    </Alert>
                )
            )}
        </Container>
    );
};

export default CommissionChart;
